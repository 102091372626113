import { GetStaticProps } from 'next';
import { NextSeo } from 'next-seo';

import { withGlobalDefaultStaticProps } from '../lib/next-hooks/withGlobalDefaultStaticProps';
import { get404ErrorPageSeoConfig } from '../lib/seo/pageSeoConfigs';
import ErrorPageRenderer from '../renderers/ErrorPageRenderer';
import { NextPageWithLayout } from '../types/global-types';

type Page404StaticProps = {};

export const getStaticProps: GetStaticProps = async (context) => {
  return withGlobalDefaultStaticProps(context, { props: {} });
};

const Page404: NextPageWithLayout<Page404StaticProps> = (props) => {
  const { title, description } = get404ErrorPageSeoConfig();
  return (
    <>
      <NextSeo noindex title={title} description={description} />
      <ErrorPageRenderer title={title} description={description} />
    </>
  );
};
Page404.displayName = 'Page404';
export default Page404;
