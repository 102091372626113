import React from 'react';

type ErrorPageRenderer = {
  title: string;
  description: string;
};
const ErrorPageRenderer: React.FC<ErrorPageRenderer> = React.memo(({ title, description }) => {
  return (
    <div className="place-content-center h-[400px] flex">
      <div className="text-center text-pureWhite p-10 self-center">
        <h1 className="mb-2 font-bold text-8xl">{title}</h1>
        <p className="mt-4 text-gray-500">{description}</p>
      </div>
    </div>
  );
});
export default ErrorPageRenderer;
ErrorPageRenderer.displayName = 'ErrorPageRenderer';
